import FormField from '../../FormControls/FormField'
import withRequired from '../../../higher-order-components/withRequired'
import requiredFields from './requiredFields'

const finalRequiredFields = [
  // add conditionally required fields here
  ...requiredFields,
]

// we add required field information here because it is form-specific

export default withRequired(finalRequiredFields)(FormField)
