import React from 'react'
import PropTypes from 'prop-types'
import useSteps from '../../../hooks/useSteps'
import useSubmitSuccess from '../../../hooks/useSubmitSuccess'
import steps from './steps'
import Header from './Header'
import './styles.scss'

const DetailedRequestForm = ({
  onSubmit,
  headerRightElement,
  formVariant,
  propertyId,
  offerType,
  values,
  isMD,
}) => {
  const {
    currentStep,
    next,
    prev,
    gotoLast,
    StepComponent,
    isStep2,
    numberSteps,
  } = useSteps({ steps })

  const scrollElement = React.createRef()

  const scrollUp = () =>
    isMD &&
    scrollElement.current &&
    scrollElement.current.scroll({ top: 0, left: 0, behavior: 'smooth' })

  const nextAndScrollUp = () => {
    next()
    scrollUp()
  }

  const prevAndScrollUp = () => {
    prev()
    scrollUp()
  }

  const { wrappedSubmitHandler, success } = useSubmitSuccess({
    submitHandler: onSubmit,
    onSuccess: gotoLast,
  })

  const stepProps = {
    onSubmit: isStep2 ? wrappedSubmitHandler : nextAndScrollUp,
    formVariant,
    propertyId,
    offerType,
    values,
    isMD,
    goBack: prevAndScrollUp,
  }

  const successClass = success ? 'success' : ''

  return (
    <div
      className={`dhsv_detailedrequest_form step-${currentStep} ${successClass}`}
    >
      <Header
        currentStep={currentStep}
        numberSteps={numberSteps}
        headerRightElement={headerRightElement}
      />
      <div className="body" ref={scrollElement}>
        <StepComponent {...stepProps} />
      </div>
    </div>
  )
}

DetailedRequestForm.defaultProps = {
  formVariant: 'list',
}

DetailedRequestForm.propTypes = {
  onSubmit: PropTypes.func,
  submitSucceeded: PropTypes.bool,
  headerRightElement: PropTypes.node,
  formVariant: PropTypes.oneOf(['single', 'list']),
  propertyId: PropTypes.number,
  offerType: PropTypes.string,
  values: PropTypes.object,
  isMD: PropTypes.bool,
}

export default DetailedRequestForm
