import React from 'react'
import PropTypes from 'prop-types'
import range from 'ramda/es/range'
import l10n from '../../../shared/l10n'

const {
  DetailedRequestForm: { step_labels },
} = l10n

const Header = ({ currentStep, numberSteps, headerRightElement }) => {
  return (
    <div className={`header`}>
      <div className={'wrapper'}>
        {range(1, numberSteps + 1).map(s => {
          const activeClass = currentStep == s ? 'active' : ''
          return (
            <div className={`step ${activeClass}`} key={s}>
              <span>
                <b>{s}</b> {step_labels[s - 1]}
              </span>
            </div>
          )
        })}
      </div>
      {headerRightElement ? (
        <div className="header-right">{headerRightElement}</div>
      ) : null}
    </div>
  )
}

Header.propTypes = {
  currentStep: PropTypes.number,
  numberSteps: PropTypes.number,
  headerRightElement: PropTypes.node,
}

export default Header
