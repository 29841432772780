export const step1 = ['startOfLease']
export const step2 = [
  'name',
  'company',
  'email',
  'phone',
  'phoneCountryCode',
  'phoneSuffix',
]

export default [...step1, ...step2]
