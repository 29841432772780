import React from 'react'
import IonIcon from '../IonIcon'
import globalData from '../../shared/globalData'

const { social_icons = [] } = globalData

const SocialIcons = () => (
  <div className="social-icons">
    {social_icons.map(({ name, url }, i) => (
      <a key={i} href={url} rel="noreferrer" target="_blank" aria-label={name}>
        <IonIcon slug={name} />
      </a>
    ))}
  </div>
)

SocialIcons.propTypes = {}

export default SocialIcons
