import React from 'react'
import l10n from '../../../../shared/l10n'
import globalData from '../../../../shared/globalData'
import logo from '../../../../../images/AOC_Logo.svg'
import SocialIcons from '../../../SocialIcons'
import { trackGtmEventGa } from '../../../../util/tracking'

const {
  DetailedRequestForm: { success1, success2, success_cta, success_paragraph },
} = l10n

const { home_url } = globalData

const Success = () => {
  return (
    <div className="dhsv_form success">
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2">
          <img className="aoc-logo" src={logo} alt="AOC Logo" />
          <h2 className="h3">
            {success1}
            <br />
            {success2}
          </h2>
          <a
            href={home_url}
            className="btn btn--secondary btn--rounded"
            onClick={() => {
              trackGtmEventGa(
                'Button Click',
                'Back to Homepage',
                'Checkout Step 3'
              )
            }}
          >
            {success_cta}
          </a>
          <hr />
          <p>{success_paragraph}</p>
          <SocialIcons />
        </div>
      </div>
    </div>
  )
}

export default Success
