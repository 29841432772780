import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Component from './component'
import { sIsMD } from '../../../redux/modules/browser'
import { submit } from './module'

const WithData = connect(
  state => ({
    isMD: sIsMD(state),
  }),
  (dispatch, props) =>
    bindActionCreators(
      {
        onSubmit: data => submit(data, props),
      },
      dispatch
    )
)(Component)

WithData.defaultProps = {
  formVariant: 'list',
}

WithData.propTypes = {
  formVariant: PropTypes.oneOf(['single', 'list']),
  propertyId: PropTypes.number,
  offerType: PropTypes.string,
}

export default WithData
