import React, { useEffect } from 'react'
import withForm from '../withForm'
import { formPropTypes } from 'redux-form/es/propTypes'
import { step1 as requiredFields } from '../requiredFields'
import { validateRequiredAsPairs } from '../../../../util/form'
import FormField from '../FormField'
import FormFieldNoRequired from '../../../FormControls/FormField'
import Select from '../../../FormControls/Select'
import Checkbox from '../../../FormControls/Checkbox'
import NetworkError from '../../../FormControls/NetworkError/data'
import ValidationMessages from '../../../FormControls/ValidationMessages/data'
import l10n from '../../../../shared/l10n'
import MyList from '../../../MyList'
import MandatoryFields from '../../../FormControls/MandatoryFields'
import { trackGtmEventGa } from '../../../../util/tracking'

const {
  DetailedRequestForm: {
    heading1,
    start_of_lease_options,
    start_of_lease_label,
    start_of_lease_placeholder,
  },
  forms: { next },
} = l10n

const mkOption = value => ({
  label: value,
  value,
})

const numberOfPersonsOptions = [
  '1-3',
  '4-6',
  '7-14',
  '15-29',
  '30-49',
  '50 - 100',
  '100+',
].map(mkOption)

const startOfLeaseOptions = start_of_lease_options.map(mkOption)

const Step1 = ({
  handleSubmit,
  submitting,
  form,
  invalid,
  formVariant,
  propertyId,
  isMD,
  numberOfPersonsDisabled,
  clearFields,
  ...rest
}) => {
  const submittingClass = submitting ? 'submitting' : ''
  const isSingle = formVariant === 'single'

  const myListProps = {
    open: true,
    layout: 'detaillist',
    openLinksInNewWindow: true,
    ...(isSingle
      ? {
          items: [propertyId],
        }
      : {}),
  }

  useEffect(() => {
    if (numberOfPersonsDisabled) {
      clearFields(false, false, ['numberOfPersons'])
    }
  }, [numberOfPersonsDisabled])

  useEffect(() => {
    const translateOfferType = offerType => {
      return (
        {
          cod: 'coworking',
          vo: 'virtualOffice',
          po: 'officeFor',
          lto: 'officeFor',
          mr: 'meetingRoom',
          ms: 'membership',
        }[offerType] || ''
      )
    }

    if (rest.offerType) {
      rest.reset('detailedRequest')
      rest.change(translateOfferType(rest.offerType), true)
    }
  }, [rest.offerType])

  return (
    <div className="row">
      {!isMD && (
        <div className="col-12 col-lg-6">
          <MyList {...myListProps} />
        </div>
      )}
      <div className="col-12 col-lg-6">
        <form
          className={`dhsv_form ${submittingClass}`}
          onSubmit={handleSubmit}
        >
          <NetworkError />
          <ValidationMessages form={form} />

          <div className="row">
            <div className="col-12">
              <h2 className="h3">{heading1}</h2>
            </div>
            <div className="col-4">
              <FormField name="officeFor" component={Checkbox} />
            </div>
            <div className="col-8 col-md-6">
              <FormField
                name="numberOfPersons"
                component={Select}
                options={numberOfPersonsOptions}
                isDisabled={numberOfPersonsDisabled}
                isSearchable={false}
              />
            </div>
            <div className="col-12">
              <FormField name="coworking" component={Checkbox} />
              <FormField name="meetingRoom" component={Checkbox} />
              <FormField name="virtualOffice" component={Checkbox} />
              <FormField name="membership" component={Checkbox} />
            </div>
            <div className="col-12">
              <hr />
            </div>
          </div>
          <div className="row start-of-lease">
            <div className="col-sm-4 left">
              <h2 className="h3">{start_of_lease_label}*</h2>
            </div>
            <div className="col-sm-6">
              <FormFieldNoRequired
                name="startOfLease"
                component={Select}
                options={startOfLeaseOptions}
                placeholder={start_of_lease_placeholder}
                isSearchable={false}
              />
            </div>
            <div className="col-12">
              <MandatoryFields />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                disabled={invalid}
                onClick={() =>
                  trackGtmEventGa('Button Click', 'Continue', 'Checkout Step 1')
                }
              >
                {next}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

Step1.propTypes = {
  ...formPropTypes,
}

const validate = validateRequiredAsPairs(requiredFields)

export default withForm(validate)(Step1)
