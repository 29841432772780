import reduxForm from 'redux-form/es/reduxForm'
import { connect } from 'react-redux'
import formValueSelector from 'redux-form/es/formValueSelector'
import compose from 'ramda/es/compose'
import l10n from '../../../shared/l10n'
import { formName } from './module'
import { createSelector } from 'reselect'

const {
  DetailedRequestForm: { defaultMessageSingle, defaultMessageList },
} = l10n

const withForm = (validate = () => ({})) => WrappedComponent =>
  reduxForm({
    form: formName,
    validate,
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(WrappedComponent)

const sFieldValue = field => state => formValueSelector(formName)(state, field)
const sNumberOfPersonsDisabled = createSelector(
  sFieldValue('officeFor'),
  sFieldValue('coworking'),
  sFieldValue('meetingRoom'),
  sFieldValue('membership'),
  (officeFor, coworking, meetingRoom, membership) => {
    return !(officeFor || coworking || meetingRoom || membership)
  }
)

const withData = WrappedComponent =>
  connect((state, { formVariant }) => ({
    numberOfPersonsDisabled: sNumberOfPersonsDisabled(state),
    initialValues: {
      message:
        formVariant == 'single' ? defaultMessageSingle : defaultMessageList,
    },
  }))(WrappedComponent)

export default validate => compose(withData, withForm(validate))
