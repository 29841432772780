import React from 'react'
import withForm from '../withForm'
import { formPropTypes } from 'redux-form/es/propTypes'
import { step2 as requiredFields } from '../requiredFields'
import {
  validateRequiredAsPairs,
  composeValidations,
  validateEmailFields,
} from '../../../../util/form'
import FormField from '../FormField'
import TextField from '../../../FormControls/TextField'
import Checkbox from '../../../FormControls/Checkbox'
import NetworkError from '../../../FormControls/NetworkError/data'
import ValidationMessages from '../../../FormControls/ValidationMessages/data'
import l10n from '../../../../shared/l10n'
import MandatoryFields from '../../../FormControls/MandatoryFields'
import Fields from 'redux-form/es/Fields'
import PhoneFields from '../../../FormControls/PhoneFields'
import { trackGtmEventGa } from '../../../../util/tracking'

const {
  DetailedRequestForm: { heading2, submit },
  forms: { prev },
} = l10n

const Step2 = ({ handleSubmit, submitting, form, invalid, goBack }) => {
  const submittingClass = submitting ? 'submitting' : ''

  return (
    <div className="row">
      <div className="col-12 col-md-8 offset-md-2">
        <form
          className={`dhsv_form ${submittingClass}`}
          onSubmit={handleSubmit}
        >
          <NetworkError />
          <ValidationMessages form={form} />

          <div className="row">
            <div className="col-12">
              <h2 className="h3">{heading2}</h2>
            </div>
            <div className="col-12 col-md-6">
              <FormField
                name="name"
                icon="account_circle"
                component={TextField}
                maxLength={100}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormField
                name="company"
                icon="home"
                component={TextField}
                maxLength={100}
              />
            </div>
            <div className="col-12 col-md-6">
              <FormField
                name="email"
                icon="laptop"
                component={TextField}
                maxLength={100}
              />
            </div>
            <div className="col-12 col-md-6">
              <Fields
                names={['phone', 'phoneCountryCode', 'phoneSuffix']}
                component={PhoneFields}
              />
            </div>
            <div className="col-12">
              <FormField
                name="message"
                icon="mail_outline"
                component={TextField}
                multiline
                maxLength={750}
              />
              <MandatoryFields />
            </div>
            <div className="col-12">
              <FormField name="marketingConsent" component={Checkbox} />
            </div>
            <div className="col-12 d-flex">
              <button
                className="back"
                type="submit"
                onClick={() => {
                  goBack()
                  trackGtmEventGa('Button Click', 'Back', 'Checkout Step 2')
                }}
              >
                <span className="material-icons">undo</span>
                {prev}
              </button>
              <button
                className="submit"
                type="submit"
                disabled={submitting || invalid}
              >
                {submit}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

Step2.propTypes = {
  ...formPropTypes,
}

const validate = composeValidations(
  validateEmailFields(['email']),
  validateRequiredAsPairs(requiredFields)
)

export default withForm(validate)(Step2)
