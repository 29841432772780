import { submitForm } from './form'
import { unwrapValues } from '../../util/form'
import { trackGtmEvent } from '../../util/tracking'
import { sItems, clearList } from './mylist'

export const formName = 'detailedRequest'

const SUBMIT_FORM = `dhsv/aoc001/${formName}Form/SUBMIT_FORM`
const SUBMIT_FORM_SUCCESS = `dhsv/aoc001/${formName}Form/SUBMIT_FORM_SUCCESS`
const SUBMIT_FORM_FAILURE = `dhsv/aoc001/${formName}Form/SUBMIT_FORM_FAILURE`

export const submit = (formData, { formVariant, propertyId }) => (
  dispatch,
  getState
) => {
  const state = getState()
  const propertyIds = sItems(state)
  const data = {
    ...unwrapValues(formData),
    formVariant,
    propertyId,
    propertyIds,
  }

  dispatch({ type: SUBMIT_FORM, meta: { data } })

  return dispatch(
    submitForm({
      formName,
      data,
      successType: SUBMIT_FORM_SUCCESS,
      failureType: SUBMIT_FORM_FAILURE,
    })
  ).then(() => {
    trackGtmEvent('detailedrequest-submit', { formVariant })

    if (['list', 'ppc'].includes(formVariant)) {
      dispatch(clearList())
    }
  })
}
